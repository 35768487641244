import React, { useEffect, useState } from "react"

import {

  H4,
} from "../elements/shared/texts"
import "../styles/post.css"

import CoursePostHeader from "./CoursePostHeader"
import Accordion from "./Accordion"
import CourseBody from "./CourseBody"
import FloatingComponent from "./FloatingComponent"
import { LI, OL } from "../elements/shared/lists"
import { UseGlobalStateContext } from "../context/globalContext"
import { getTheme } from "../themes/theme"
import Lesson from "./post/Lesson";
import { PaddingContainer } from "../elements/shared/containers";
import { useDynamicDimensions } from "../elements/hooks/use-dimensions";
import { PricingPlans } from "../utils/constants";
import styled from "styled-components";
import { AccentLink } from "../elements/shared/buttons";
import NavigationBar from "./navbar";


const ShouldUpgradeBanner = styled.div `
  
`

const CoursePost = ({ data }) => {
  const [lessons, setLessons] = useState([])
  const [selectedLesson, selectLesson] = useState(false)
  const [oldLayout, setOldLayout] = useState(false)
  const [shouldUpgrade, setShouldUpgrade] = useState(false)
  const { currentTheme, userData } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)
  const dimensions = useDynamicDimensions()

  useEffect(() => {
    data.contentfulCourse.lessons = sortLessons(data.contentfulCourse.lessons)
    updateLessons()
  }, [])

  useEffect(() => {
    if (lessons.length > 0 ) {
      const firstLesson = lessons[0]
      selectLesson(firstLesson)
    }
  }, [lessons])

  useEffect(() => {
    updateLessons()
  }, [userData])

  const handleSelectedLesson = slug => {
    const lesson = data.contentfulCourse.lessons.find(item => item.slug === slug )
    selectLesson(lesson)
  }

  const updateLessons = () => {
    if (userData) {
      if (data.contentfulCourse.plan === PricingPlans.PREMIUM) {
        if (userData.isPremium) {
          setLessons(data.contentfulCourse.lessons)
          setShouldUpgrade(false)
        } else {
          if (data.contentfulCourse.lessons) {
            const freeLessonsCount = data.contentfulCourse.freeLessons
            const freeLessons = data.contentfulCourse.lessons.slice(0, freeLessonsCount)
            setLessons(freeLessons)
            setShouldUpgrade(true)
          }
        }
      } else {
        setLessons(data.contentfulCourse.lessons)
      }
    }
  }

  const sortLessons = () => {
    const sorted = data.contentfulCourse.lessons.sort((a, b) =>  new Date(a.createdAt) - new Date(b.createdAt) )
    return sorted
  }

  if (!oldLayout) {
    if (selectedLesson.isNewLayout && dimensions.width > 640) {
        return (<div>

          <CoursePostHeader data={data.contentfulCourse}
                            selectedLesson={selectedLesson}
                            oldLayout={ oldLayout}
                            setOldLayout={setOldLayout}
                            isNewLayout={selectedLesson.isNewLayout}
                            isCourse={true}
            />
        <Lesson lesson={selectedLesson}
                handleSelectedLesson={handleSelectedLesson}
                isCourse={true}
               />

          <FloatingComponent downloads={data.contentfulCourse.downloads}
                             resources={data.contentfulCourse.resources}
                            selectedLesson={selectedLesson}
          >
            <H4 >Course contents</H4>
            <OL>
              { lessons.map(lesson => (

                <Accordion
                  key={lesson.slug}
                  item={lesson}
                  selected={selectedLesson}
                  selectItem={event => {handleSelectedLesson(lesson.slug)}} />

              ))}

              {
                shouldUpgrade && <ShouldUpgradeBanner>
                  <AccentLink to={"/account/dashboard"}> Upgrade to continue </AccentLink>
                </ShouldUpgradeBanner>
              }

            </OL>
          </FloatingComponent>
      </div>)
    }
  }

  return (
    <PaddingContainer>
      <NavigationBar/>
        <div className="container mx-auto px-0 md:py-20 py-8">

          <CoursePostHeader data={data.contentfulCourse}
                            selectedLesson={selectedLesson}
                            oldLayout={oldLayout}
                            setOldLayout={setOldLayout}
                            isNewLayout={selectedLesson.isNewLayout}
          />

         <FloatingComponent downloads={data.contentfulCourse.downloads}
                            resources={data.contentfulCourse.resources} >
           <H4 >Course contents</H4>
           <OL>
             { lessons.map(lesson => (

              <Accordion
              key={lesson.slug}
                item={lesson}
                selected={selectedLesson}
                selectItem={event => {handleSelectedLesson(lesson.slug)}} />
             ))}
           </OL>
         </FloatingComponent>
          {
            selectedLesson && (
              <CourseBody  body={selectedLesson.script.childMdx.body} />
            )
          }
        </div>
    </PaddingContainer>

  )
}

export default CoursePost
